<template>
  <div id="app" class="home" >
    <!-- banner -->
    <div class="home-banner">
      <div class="banner-container">
        <h1 class="blog-title animated zoomIn">
          从何时开始忌讳空山无人，从何时开始怕遥望星尘。
        </h1>
        <!-- 联系方式 -->
        <div class="blog-contact animated zoomIn">
          <a class="github circular icon button" data-content="https://gitee.com/ws0126" data-position="bottom center" style="margin-right: 50px"><i class="github icon"></i></a>
          <a class="wechat circular icon button" style="margin-right: 50px"><i class="weixin icon"></i></a>
          <a class="qq circular icon button" data-content="1536244577" data-position="bottom center"><i class="qq icon"></i></a>
        </div>
        <div class="ui wechat-qr flowing popup transition hidden">
          <img src="../assets/wechat.jpg" alt="" class="ui rounded image" style="width: 110px">
        </div>
        <!-- 向下滚动 -->
        <div class="scroll-down" @click="scrollDown">
          <h4>向下滚动<i class="el-icon-arrow-down"></i></h4>
        </div>
      </div>
      <!--中间内容,如果太窄了可放到container    <div  class="m-container m-padded-tb-big">-->
    </div>
    <div  class="m-home">
      <div class="ui container">
        <div class="ui stackable grid">
          <!--左边博客列表-->
          <div class="eleven wide column">
            <!--header-->
            <div class="ui top attached segment">
              <div class="ui middle aligned two column grid my-shadow">
                <div class="column">
                  <h3 class="ui my-blue header" >博客</h3>
                </div>
                <div class="right aligned column"><h4 class="ui header m-inline-block m-text" v-if="pagination.queryString!=='' && pagination.queryString!==null" style="height: 1px !important;">根据"{{pagination.queryString}}"的搜索结果</h4>
                  共 <h2 class="ui orange header m-inline-block m-text-thin">{{pagination.total}}</h2> 篇
                </div>
              </div>
            </div>

            <!--content-->
            <div class="ui attached  segment my-blog-shadow">

              <div class="ui padded vertical segment m-padded-tb-large" v-for="item in dataList" :key="item.id">
                <div class="ui middle aligned mobile reversed stackable grid">
                  <div class="eleven wide column" style="cursor:pointer;">
                    <h3 class="ui header" @click="toBlog(item.id)">{{item.title}}</h3>
                    <p class="m-text" @click="toBlog(item.id)">{{item.description}}</p>
                    <div class="ui grid">
                      <div class="eleven wide column">
                        <div class="ui mini horizontal link list">
                          <div class="item">
                            <img v-bind:src = item.creator.avatar class="ui avatar image">
                            <div class="content"><a class="header">{{item.creator.nickname}}</a></div>
                          </div>
                          <div class="item">
                            <i class="calendar icon"></i> {{item.createTime}}
                          </div>
                          <div class="item">
                            <i class="eye icon"></i> {{item.views}}
                          </div>
                          <div class="item">
                            <i class="thumbs up outline icon"></i> {{item.thumbs}}
                          </div>
                        </div>
                      </div>
                      <div class="right aligned five wide column">
                        <a target="_self" class="ui my-blue basic label m-padded-tiny m-text-thin">{{item.type.typeName}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="five wide column">
                    <a target="_self">
                      <img v-bind:src=item.firstPictureUrl @click="toBlog(item.id)" alt="" class="ui rounded image">
                    </a>
                  </div>

                </div>
              </div>
            </div>

            <!--footer-->
            <div class="ui bottom attached segment my-blog-shadow">
              <div class="pagination-container">
                <el-pagination
                  class="pagiantion"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pagination.page"
                  :page-sizes="[1 , 2 , 3]"
                  :page-size="pagination.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pagination.total">
                </el-pagination>
              </div>
            </div>

          </div>

          <!--右边的top-->
          <div class="five wide column" style="left: 34px !important;">

            <!--分类-->
            <div class="ui segments my-shadow">
              <div class="ui secondary segment">
                <div class="ui two column grid">
                  <div class="column">
                    <i class="idea icon"></i>分类
                  </div>
                  <div class="right aligned column">
                    <a href="/#/types" target="_self">more <i class="angle double right icon"></i></a>
                  </div>
                </div>
              </div>
              <div class="ui my-blue segment">
                <div class="ui fluid vertical menu">
                  <template v-for="(item, index) in typeList">
                    <a class="item" v-if='index<6' :key="item.id" @click="toType(item.id)">
                      <span class="blackFont">{{item.typeName}}</span>
                      <div class="ui blue basic left pointing label">{{ index + 1 }}</div>
                    </a>
                  </template>
                </div>
              </div>
            </div>

            <!--标签-->
            <div class="ui segments m-margin-top-large my-shadow">
              <div class="ui secondary segment">
                <div class="ui two column grid">
                  <div class="column">
                    <i class="tags icon"></i>标签
                  </div>
                  <div class="right aligned column">
                    <a href="/#/tags" target="_self">more <i class="angle double right icon"></i></a>
                  </div>
                </div>
              </div>
              <div class="ui my-blue segment">
                <template v-for="(item, index) in tagList">
                  <a target="_self" class="ui blue basic left pointing label m-margin-tb-tiny" v-if='index<15' :key="item.id" @click="toTag(item.id)" style="margin: 20px 10px ">
                    <span class="blackFont">{{item.tagName}}</span>
                    <div class="detail">{{ index + 1 }}</div>
                  </a>
                </template>
              </div>
            </div>

            <!--最新推荐-->
            <div class="ui segments m-margin-top-large my-shadow">
              <div class="ui secondary segment ">
                <i class="bookmark icon"></i>最新推荐
              </div>
              <div class="ui segment" v-for="item in latestBlogList" :key="item.id">
                <a  target="_self" class="m-black m-text-thin" style="cursor:pointer;" v-text="item.title" @click="toBlog(item.id)"></a>
              </div>
            </div>

            <!--二维码-->
<!--            <h4 class="ui horizontal divider header m-margin-top-large">扫码关注我</h4>
            <div class="ui centered card my-shadow" style="width: 11em">
              <img src="https://r.photo.store.qq.com/psc?/V53KcXfb1umonn4HbITu3rINxs43TczD/45NBuzDIW489QBoVep5mcaapv*CZPLor9HYeVrOOiVLnyRm8OUpwb6xeJ6lITPL.CQBAMN*ufWnqF4BJBqO4o0iDboC.V.GwA1i2AehYs7g!/r" alt="" class="ui rounded image" >
            </div>-->

          </div>

        </div>
      </div>
    </div>
    <br>
    <br>
    <Footer></Footer>
<!--    &lt;!&ndash; 主体组件 &ndash;&gt;-->
<!--    <index style="width: 100%"></index>-->
  </div>

</template>

<script>
import { Notification } from 'element-ui'
import Footer from '../components/layout/Footer'
export default {
  // 注册组件
  components: {
    Footer
  },
  data () {
    return {
      dataList: [], // 当前页要展示的博客分页列表数据
      typeList: [], // 分类列表的数据
      tagList: [], // 标签列表的数据
      latestBlogList: [], // 最新发布的博客列表的数据
      pagination: { // 分页相关模型数据
        page: 1, // 当前页码
        size: 3, // 每页显示的记录数
        total: 0, // 总记录数
      },
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: '',
      tip: false,
      obj: {
        isEnd: false,
        speed: 300,
        singleBack: false,
        sleep: 0,
        type: 'rollback',
        backSpeed: 40,
        sentencePause: true
      },
      articleList: [],
      blogInfo: {},
      current: 1,
      firstVisitFlag: this.$constant.RESPONSE_CODE.FAILED,
    }
  },
  created () {
   // this.message()
   // // this.getUser()
    this.getBlogList()
    this.getTypeList()
    this.getTagList()
    this.getLatestList()
    this.firstVisit()
  //  this.reload()
  },
  methods: {

   // 判断客户端是否是首次访问
    async firstVisit() {
      const res = await this.$http.get("/user/firstVisit");
      if (!res.data) {
        // 用户首次访问提示信息
        Notification({
          title: '欢迎访问',
          message: '首次访问，资源加载较慢，请见谅~',
          duration: 0,
          type: "success",
        })
        this.firstVisitFlag = res.code;
      }
    },

    reload () {
      window.sessionStorage.setItem('reloadAdmin', 'true')
      const str = window.sessionStorage.getItem('reload')
      if (str !== null) {
        window.location.reload()
        window.sessionStorage.removeItem('reload')
      }
    },
    message () {
      const messageFlag = sessionStorage.getItem('messageFlag')
      if (messageFlag == null) {
        Notification({
          title: '消息',
          message: '在本站中各位可以创建用户发布博客、评论、留言等进行测试，但是没有实际意义的博客会被站主删除，望各位知悉',
          duration: 0
        })
        const h = this.$createElement
        Notification({
          title: '通知',
          message: h('i', { style: 'color: teal' }, '此次更新了一个新模块：聊天室模块，并且已经支持上传图片等功能；后花园也有新功能，测试还不完善可能还有bug。' +
            '有关ElasticSearch服务的2核4G服务器过期了（由于贫穷），搜索功能暂时无法使用，敬请谅解！'),
          duration: 0
        })
      }
      sessionStorage.setItem('messageFlag', 'true')
    },
    // 初始化
    scrollDown () {
      window.scrollTo({
        behavior: 'smooth',
        top: document.documentElement.clientHeight
      })
    },
    search () {
      this.getBlogList()
      this.pagination.queryString = null
    },
    toTag (tagId) {
      this.$router.push( {
        path: "/tags",
        query: {
          tagId: tagId
        }
      } )
    },
    toType (typeId) {
      this.$router.push({
        path: "/types",
        query: {
          typeId: typeId
        }
      })
    },
    toBlog (blogId) {
      this.$router.push({
        path: "/blog",
        query: {
          blogId: blogId,
          firstVisitFlag: this.firstVisitFlag
        }
      })
    },
    async getLatestList () {
      const res = await this.$http.get('/blog/listBlogs', {
        sortField: "create_time" , sortOrder: "DESC" ,
        published: this.$constant.PUBLISH_STATUS.PUBLISHED.CODE,
        page: 1,
        size: 5
      } )
      this.latestBlogList = res.data.list
    },
    async getTypeList () {
      const res = await this.$http.get('/type/listTypes', {
        size: 3,
      } )
      this.typeList = res.data.list
    },
    async getTagList () {
      const res = await this.$http.get('/tag/listTags', {
        size: 8
      } )
      this.tagList = res.data.list
    },

    // 分页查询
    async listBlogs() {
      const res = await this.$http.get('/blog/listBlogs', param)
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        this.$message.error('获取博客列表失败！')
      }

      this.pagination.total = res.data.total;
      this.blogList = res.data.list
    },

    // 获取主页博客
    async getBlogList () {
      // 博客查询条件
      const str = sessionStorage.getItem('queryString')
      if (str !== null) {
        this.pagination.queryString = str
        sessionStorage.removeItem('queryString')
        this.$message.info('搜索结果已经显示在页面下方')
      }
      // 发送ajax，提交分页请求（页码，每页显示条数，查询条件)
      const param = {
        page: this.pagination.page,
        size: this.pagination.size,
        published: this.$constant.PUBLISH_STATUS.PUBLISHED.CODE,
        sortField: "create_time" ,
        sortOrder: "DESC" ,
        archive: 1
      }
      const res = await this.$http.get('/blog/listBlogs', param)
      // 解析controller响应回的数据
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        return this.$message.error('获取博客列表失败！')
      }
      this.pagination.total = res.data.total;
      this.dataList = res.data.list
    },

   /* getUser () {
      this.user = window.sessionStorage.getItem('user')
      if (this.user != null) {
        this.nickname = JSON.parse(this.user).nickname
        this.avatar = JSON.parse(this.user).avatar
      }
    },*/
    logout () {

    },
    handleCurrentChange (currentPage) {
      // 设置最新的页码
      this.pagination.page = currentPage
      // 重新调用getBlogList方法进行分页查询
      this.getBlogList()
    },
    handleSizeChange (newSize) {
      this.pagination.size = newSize
      this.getBlogList()
    }
  },
  mounted () {
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })
    $('.ui.dropdown').dropdown({
      on: 'hover'
    })
    $('.wechat').popup({
      popup: $('.wechat-qr'),
      position: 'bottom center'
    })
    $('.qq').popup()
    $('.github').popup()
  }
}
</script>
<style>
  .m-home {
    padding-top: 105vh !important;
    padding-bottom: 0px !important;
  }
  .home-banner {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    height: 100vh;
    background: url("https://wsong.oss-cn-hangzhou.aliyuncs.com/%20song-blog/blog-main-image/%20823451718207750144home.jpg") center center /
    cover no-repeat;
    background-attachment: fixed;
    background-color: white;
    text-align: center;
    color: #fff !important;
    animation: header-effect 1s !important;
  }
  .banner-container {
    margin-top: 43vh;
    line-height: 1.5;
    color: #eee;
  }
  .blog-contact a {
    color: #fff !important;
  }
  .card-info-social {
    line-height: 40px;
    text-align: center;
    font-size: 1.5rem;
    margin: 6px 0 -6px;
  }
  .left-radius {
    border-radius: 8px 0 0 8px !important;
    order: 0;
  }
  .right-radius {
    border-radius: 0 8px 8px 0 !important;
    order: 1;
  }
  .article-wrapper {
    font-size: 14px;
  }
  @media (min-width: 760px) {
    .blog-title {
      font-size: 2.5rem;
    }
    .blog-intro {
      font-size: 1.5rem;
    }
    .blog-contact {
      line-height: 40px;
      text-align: center;
      font-size: 1.5rem;
      margin: 6px 0 -6px;
    }
    .home-container {
      max-width: 1200px;
      margin: calc(100vh - 50px) auto 0 auto;
      padding: 0 3px;
    }
    .article-card {
      display: flex;
      align-items: center;
      height: 280px;
      width: 100%;
      margin-top: 20px;
    }
    .article-cover {
      overflow: hidden;
      height: 100%;
      width: 45%;
    }
    .on-hover {
      transition: all 0.6s;
    }
    .article-card:hover .on-hover {
      transform: scale(1.1);
    }
    .article-wrapper {
      padding: 0 2.5rem;
      width: 55%;
    }
    .article-wrapper a {
      font-size: 1.5rem;
      transition: all 0.3s;
    }
  }
  @media (max-width: 759px) {
    .blog-title {
      font-size: 24px;
    }
    .blog-contact {
      font-size: 1.25rem;
      line-height: 2;
    }
    .home-container {
      width: 100%;
      margin: calc(100vh - 66px) auto 0 auto;
    }
    .article-card {
      margin-top: 1rem;
    }
    .article-cover {
      border-radius: 8px 8px 0 0 !important;
      height: 230px !important;
      width: 100%;
    }
    .article-cover div {
      border-radius: 8px 8px 0 0 !important;
    }
    .article-wrapper {
      padding: 1.25rem 1.25rem 1.875rem;
    }
    .article-wrapper a {
      font-size: 1.25rem;
      transition: all 0.3s;
    }
  }
  .scroll-down {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .scroll-down i {
    font-size: 2rem;
  }
  .article-wrapper a:hover {
    color: #8e8cd8;
  }
  .article-info {
    font-size: 95%;
    color: #858585;
    line-height: 2;
    margin: 0.375rem 0;
  }
  .article-info a {
    font-size: 95%;
    color: #858585 !important;
  }
  .article-content {
    line-height: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .blog-wrapper {
    position: sticky;
    top: 10px;
  }
  .blog-card {
    line-height: 2;
    padding: 1.25rem 1.5rem;
  }
  .author-wrapper {
    text-align: center;
  }
  .blog-info-wrapper {
    display: flex;
    justify-self: center;
    padding: 0.875rem 0;
  }
  .blog-info-data {
    flex: 1;
    text-align: center;
  }
  .blog-info-data a {
    text-decoration: none;
  }
  .collection-btn {
    text-align: center;
    z-index: 1;
    font-size: 14px;
    position: relative;
    display: block;
    background-color: #49b1f5;
    color: #fff !important;
    height: 32px;
    line-height: 32px;
    transition-duration: 1s;
    transition-property: color;
  }
  .collection-btn:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #ff7242;
    content: "";
    transition-timing-function: ease-out;
    transition-duration: 0.5s;
    transition-property: transform;
    transform: scaleX(0);
    transform-origin: 0 50%;
  }
  .collection-btn:hover:before {
    transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
    transform: scaleX(1);
  }
  .author-avatar {
    transition: all 0.5s;
  }
  .author-avatar:hover {
    transform: rotate(360deg);
  }
  .web-info {
    padding: 0.25rem;
    font-size: 0.875rem;
  }
  .scroll-down-effects {
    color: #eee !important;
    text-align: center;
    text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
    line-height: 1.5;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    animation: scroll-down-effect 1.5s infinite;
  }
  @keyframes scroll-down-effect {
    0% {
      top: 0;
      opacity: 0.4;
      filter: alpha(opacity=40);
    }
    50% {
      top: -16px;
      opacity: 1;
      filter: none;
    }
    100% {
      top: 0;
      opacity: 0.4;
      filter: alpha(opacity=40);
    }
  }
  .big i {
    color: #f00;
    animation: big 0.8s linear infinite;
  }
  @keyframes big {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
</style>
